//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions} from 'vuex'
import i18n from '@/vueI18n'
import moment from 'moment'

export default {
    name: 'app-payment-transaction-list-table',
    props:{
        year: {
            type: Number
        },
        month: {
            type: Number
        },
        page: {
            type: Number
        },
    },
    data(){
        return {
            transactionTab: 'trips',                 
            columnsTrips: [
                {
                    name: 'transactionNumber',
                    required: true,
                    label: this.i18n('entities.payment.fields.transactionNumber'), 
                    align: 'center',
                    field: 'transactionNumber',
                },
                // {
                //     name: 'tripId',
                //     required: true,
                //     label: this.i18n('entities.payment.fields.tripId'), 
                //     align: 'center',
                //     field: 'tripId',
                // },
                {
                    name: 'tripName',
                    required: true,
                    label: this.i18n('entities.payment.fields.tripName'), 
                    align: 'center',
                    field: 'tripName',
                },
                {
                    name: 'tripStatus',
                    required: true,
                    label: this.i18n('entities.payment.fields.tripStatus'), 
                    align: 'center',
                    field: 'tripStatus',
                },
                {
                    name: 'amount',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.amount'),
                    field: 'amount',
                },
                {
                    name: 'tripDate',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.tripDate'),
                    field: 'tripDate',
                },
                {
                    name: 'operation',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.operation'),
                    field: 'operation',
                },
                {
                    name: 'paymentMethod',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.paymentMethod'),
                    field: 'paymentMethod',
                },
                {
                    name: 'payerName',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.payerName'),
                    field: 'payerName',
                },
                {
                    name: 'tourguideName',
                    align: 'center',
                    label: this.i18n('entities.payment.fields.tourguideName'),
                    field: 'tourguideName',
                },
            ],
           
            rowsTrips: [
                {
                    transactionNumber: '#123456',
                    tripId: 'tripId',
                    tripName: 'Makkah Trip',
                    tripStatus: 'pending',
                    amount: '300 SR',
                    tripDate: 'Sat 25 March',
                    operation: 'credit',
                    clientName: 'Ghada',
                    tourguideName: 'Ahmed'
                },
                {
                    transactionNumber: '#123456',
                    tripId: 'tripId',
                    tripName: 'Makkah Trip',
                    tripStatus: 'pending',
                    amount: '300 SR',
                    tripDate: 'Sat 25 March',
                    operation: 'credit',
                    clientName: 'Ghada',
                    tourguideName: 'Ahmed'
                },
                {
                    transactionNumber: '#123456',
                    tripId: 'tripId',
                    tripName: 'Makkah Trip',
                    tripStatus: 'pending',
                    amount: '300 SR',
                    tripDate: 'Sat 25 March',
                    operation: 'credit',
                    clientName: 'Ghada',
                    tourguideName: 'Ahmed'
                },
                {
                    transactionNumber: '#123456',
                    tripId: 'tripId',
                    tripName: 'Makkah Trip',
                    tripStatus: 'pending',
                    amount: '300 SR',
                    tripDate: 'Sat 25 March',
                    operation: 'credit',
                    clientName: 'Ghada',
                    tourguideName: 'Ahmed'
                },
                {
                    transactionNumber: '#123456',
                    tripId: 'tripId',
                    tripName: 'Makkah Trip',
                    tripStatus: 'pending',
                    amount: '300 SR',
                    tripDate: 'Sat 25 March',
                    operation: 'credit',
                    clientName: 'Ghada',
                    tourguideName: 'Ahmed'
                },
                
            ],

            pagination: {
                page: 1,
                rowsPerPage: 10 
            },
            filter: {},
            orderBy: 'createdAt'
        }
    },
    watch:{
        year(){
            this.fetchTransactions()
        },
        month(){
            this.fetchTransactions()
        }
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
        ...mapActions({
            doFetchTripsTransaction: 'payment/list/doFetchTripsTransaction',
            doFetchWalletTransaction: 'payment/list/doFetchWalletTransaction',
            finishTripsTransactionsLoading: 'payment/list/finishTripsTransactionsLoading'
        }),

        presenterDate(row, fieldName) {
        if(this.language == 'ar'){
            return row[fieldName] ? moment(row[fieldName]).locale('ar').format("D MMM, YYYY") : 'ـــــــ';
        }
            return row[fieldName] ? moment(row[fieldName]).locale('en').format("D MMM, YYYY") : 'ـــــــ';
        },

        async goToPreviousPage(){
            this.filter = {
                month: this.month,
                year: this.year
            }
            const pagination = {
                sortBy: "desc",
                limit: this.getItemsPerPage,
                action: "prev",
                page: this.pageNumber
            }
            await this.doFetchTripsTransaction({
                filter: this.filter, 
                orderBy: this.orderBy,
                pagination
            })
            this.pageNumber -= 1
            this.finishTripsTransactionsLoading()
        },
        async loadMore(){
            this.filter = {
                month: this.month,
                year: this.year
            }
            const pagination = {
                sortBy: "desc",
                // limit: this.getItemsPerPage,
                limit: this.getItemsPerPage,
                action: "next",
                page: this.pageNumber
            }
            await this.doFetchTripsTransaction({
                filter: this.filter, 
                orderBy: this.orderBy,
                pagination
            })
            console.log(this.tripsTransactions); 
            this.pageNumber += 1
            this.finishTripsTransactionsLoading()
        },
        async fetchTransactions(){
        this.filter = {
            month: this.month,
            year: this.year
        }
        const pagination = {
            sortBy: "desc",
            limit: this.getItemsPerPage
        }
        await this.doFetchTripsTransaction({
            filter: this.filter, 
            orderBy: this.orderBy,
            pagination
        })
        console.log(this.tripsTransactions);       
        this.finishTripsTransactionsLoading()
    }
    },

   
    computed:{
        ...mapGetters({
            is_screen_xs: 'layout/is_screen_xs',
            tripsTransactions: 'payment/list/tripsTransactions',
            countTripsTransactions: 'payment/list/countTripsTransactions',
            loadingTripsTransactions: 'payment/list/loadingTripsTransactions',
            tripsTransactionsFirstPage: 'payment/list/tripsTransactionsFirstPage',
            tripsTransactionsLastPage: 'payment/list/tripsTransactionsLastPage'
        }),
        rows(){
            if(this.transactionTab == 'trips'){
                return this.rowsTrips
            }
            // return this.rowsWallet
            return this.walletTransactions
        },
        columns(){
            if(this.transactionTab == 'trips'){
                return this.columnsTrips
            }
            return this.columnsWallet
        },

        getItemsPerPage() {
          // const { screen } = this.$q;
          //   if (screen.lt.sm) {
          //       return 4;
          //   }
          //   if (screen.lt.md) {
          //       return 6;
          //   }
          //   if (screen.lt.lg) {
          //       return 9;
          //   }
          //   if (screen.lt.xl) {
          //       return 9;
          //   }
            return 10;
            // return 2;
        },
        pageNumber: {
            get: function () {
                return this.page;
            },
            set: function (value) {
                this.$emit('changePage', value)
            },
        },
        language(){
            return i18n.locale
        },
    },
    async mounted(){
        await this.fetchTransactions()
    },
   
}
