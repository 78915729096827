//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PaymentTransactionListTable from '@/modules/payment/components/payment-transaction-list-table.vue';
import PaymentWalletCard from '@/modules/payment/components/payment-wallet-card.vue';
const d = new Date();
import { mapGetters } from 'vuex'
export default {
    data(){
      return {
        search: '',
        year: (d.getFullYear()).toString(),
        yearOptions: [
        ],
        month: (d.getMonth() + 1).toString(),
        monthOptions: [
          { 
            value: '1',
            label: 'January'          
          }, 
          { 
            value: '2',
            label: 'February'          
          }, 
          { 
            value: '3',
            label: 'March'          
          }, 
          { 
            value: '4',
            label: 'April'          
          }, 
          { 
            value: '5',
            label: 'May'          
          },
          { 
            value: '6',
            label: 'June'          
          },
          { 
            value: '7',
            label: 'July'          
          },
          { 
            value: '8',
            label: 'August'          
          },
          { 
            value: '9',
            label: 'September'          
          },
          { 
            value: '10',
            label: 'October'          
          },
          { 
            value: '11',
            label: 'November'          
          },
          { 
            value: '12',
            label: 'December'          
          },
          ],
        popupShow: false,
        orderBy: 'createdAt',
        page: 1
      }
    },
    methods:{
        i18n(key, args) {
            return this.$t(key, args);
        },
        getYears(startYear) {
            var currentYear = new Date().getFullYear();
            startYear = startYear || 2021;  
            while ( startYear <= currentYear ) {
                // years.push(startYear++);
                this.yearOptions.push({
                  label: startYear.toString(),
                  value: startYear.toString()
                })
                startYear++
            }   
            return this.yearOptions
        },
        changePage(value){
          this.page = value
        },
    },
    components: {
      [PaymentTransactionListTable.name]: PaymentTransactionListTable,
      [PaymentWalletCard.name]: PaymentWalletCard
    },
    computed:{
      ...mapGetters({
        is_screen_xs: 'layout/is_screen_xs'
      })
    },
    created(){
      this.getYears(2020)
    }

}
